import { v4 as uuidv4 } from 'uuid';
import * as mqtt from 'mqtt/dist/mqtt.min.js'
import { Subject } from 'rxjs';

export type AnalogVar = {
    kind: "analog",
    sid: string,
    vid: string,
    value: number
}

export type StateVar = {
    kind: "state",
    sid: string,
    part: string,
    state: string
}

export type AlarmVar = {
    kind: "alarm",
    sid: string,
    alarm: string,
    active: boolean
}

export type StationVar = AnalogVar | StateVar | AlarmVar

export function mqttChanges(sid?: string) {
    const change$ = new Subject<StationVar>()

    const clientId = "cngman-web-" + uuidv4()
    const mqttClient = mqtt.connect('wss://vres.cz/wsapp/', { clientId: clientId })
    mqttClient.on('connect', function () {
        console.log("Pripojeno.....")
        const sub = sid ? `station/${sid}/#` : "station/#"
        mqttClient.subscribe(sub, function (err: any) {
            if (err) {
                console.log("Error connecting to MQTT server !!!")
            }
        })
    })
    mqttClient.on('message', function (topic: string, message: any) {
        const jsonStr = message.toString()
        const data = JSON.parse(jsonStr)

        const topic_splitted = topic.split("/")
        const sid = topic_splitted[1]
        const kind = topic_splitted[2]
        const vid = topic_splitted[3]

        switch (kind) {
            case "analog":
                change$.next({
                    kind: "analog",
                    sid,
                    vid,
                    value: data.val
                });
                break;
            case "state":
                change$.next({
                    kind: "state",
                    sid,
                    part: vid,
                    state: data.state
                });
                break;
            case "alarm":
                change$.next({
                    kind: "alarm",
                    sid,
                    alarm: vid,
                    active: (data.active === '1')
                })
                break;
        }
    })

    return change$
}
