import { LitElement, html, css } from 'lit'
import { customElement, state } from 'lit/decorators.js'
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User } from "firebase/auth"
import '@spectrum-web-components/button/sp-button.js'

@customElement('cng-man-login')
export class CngManLogin extends LitElement {
    @state() user: User | null = null

    override connectedCallback() {
        super.connectedCallback()

        onAuthStateChanged(getAuth(), user => {
            this.user = user
        })
    }

    doLogin() {
        const provider = new GoogleAuthProvider();

        signInWithPopup(getAuth(), provider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                console.log(token, user)
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });
    }

    doLogout() {
        signOut(getAuth())
        this.user = null
    }

    render() {
        //const btn = (this.user) ? html`<sp-button @click=${this.doLogout}>Logout</sp-button>` : html`<sp-button @click=${this.doLogin}>Login</sp-button>`
        const btn = (this.user) ? html`` : html`<sp-button @click=${this.doLogin}>Login</sp-button>`

        return html`
            <sp-theme theme="spectrum" scale="large" color="light">
                ${btn}
            </sp-theme>

        `
    }
}