import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { mqttChanges } from "./mqttsvc"
import { from, interval, Subscription, switchMap } from 'rxjs';
import { Map } from 'immutable';
import { controlStation } from './firebase';

import '@spectrum-web-components/dialog/sp-dialog.js';
import '@spectrum-web-components/button/sp-button.js';

@customElement('station-scada')
export class StationScada extends LitElement {
    @property() sid: string = 'zdiby'
    @property() template: string = 'hruby.svg'
    @state() svg: string = ''
    @state() animator: number = 0
    @state() states: Map<string, string> = Map()
    @state() alarms: Map<string, boolean> = Map()
    @state() dialogActive: string = ""

    subscriptions: Subscription[] = []

    loadSvg() {
        from(fetch(this.template)).pipe(switchMap(r => r.text())).subscribe(svg => { this.svg = svg })
    }

    setupButtonHook(tagid: string, listener) {
        const tag = this.shadowRoot?.getElementById(tagid)
        if (!tag) return;
        tag.addEventListener("click", listener)
    }

    override connectedCallback() {
        super.connectedCallback()

        this.subscriptions.push(
            mqttChanges(this.sid).subscribe(v => {
                if (v.kind === "analog") {
                    console.log(v.vid, v.value)
                    this.renderAnalog(v.vid, `${v.value}`)
                }
                if (v.kind === "state") {
                    console.log(v.part, v.state)
                    this.renderState(v.part, v.state)
                }
                if (v.kind === "alarm") {
                    this.renderAlarm(v.alarm, v.active)
                }
            }))

        this.subscriptions.push(interval(100).subscribe(_ => { this.play() }))

        this.loadSvg()
    }

    disconnectedCallback(): void {
        super.disconnectedCallback()
        this.subscriptions.forEach(s => { s.unsubscribe() })
    }

    do_bubble(id: string) {
        const ACTIVE_BUBBLE_STYLE = "fill:#007bc3;fill-opacity:1;fill-rule:evenodd;stroke:#007bc3;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0"
        const INACTIVE_BUBBLE_STYLE = "fill:#fff500;fill-opacity:1;fill-rule:evenodd;stroke:#000000;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:0"

        const tag = this.shadowRoot?.getElementById(id)
        const bubblix = this.animator % 8
        if (tag) {
            for (let i = 0; i < tag.children.length; i++) {

                if (i == bubblix) {
                    tag.children[i].setAttribute("style", ACTIVE_BUBBLE_STYLE)
                } else {
                    tag.children[i].setAttribute("style", INACTIVE_BUBBLE_STYLE)
                }
            }
        }
    }

    compressorWork(id: string) {
        const tag = this.shadowRoot?.getElementById(id)
        const stage = this.animator % 8 + 1
        if (tag) {
            for (let i = 0; i < tag.children.length; i++) {
                tag.children[i].setAttribute("xlink:href", `#pz-${stage}`)
            }
        }
    }

    venting(tid: string) {
        const tag = this.shadowRoot?.getElementById(tid)
        const stage = this.animator % 8 + 1
        if (tag) {
            tag.setAttribute("xlink:href", `#fan_${stage}`)
        }
    }

    setStyle(id: string, style: string) {
        const tag = this.shadowRoot?.getElementById(id)
        if (tag) {
            tag.setAttribute("style", style)
        }
    }

    renderValueBackground(tagid: string, stateVar: string) {
        const state = this.states.get(stateVar)
        if (!state) return;

        const map = Map<string, string>({
            "low": "fill:#00ffff;fill-rule:evenodd;stroke:none",
            "lower": "fill:#6cccbb;fill-rule:evenodd;stroke:none",
            "normal": "fill:#00cc00;fill-rule:evenodd;stroke:none",
            "higher": "fill:#ffcc00;fill-rule:evenodd;stroke:none",
            "high": "fill:#ff0000;fill-rule:evenodd;stroke:none",
            "cutted": "fill:#000000;fill-rule:evenodd;stroke:none",
        })

        const style = map.get(state) || "fill:#ffffff;fill-rule:evenodd;stroke:none"
        this.setStyle(tagid, style)
    }

    render_stage_pressure_high(tagid: string, stateVar: string) {
        const state = this.states.get(stateVar)
        if (!state) return;

        if (state == "high" || state == "higher") {
            this.setStyle(tagid, "fill-rule:evenodd;filter:url(#colorize_stage_filter)")
        } else {
            this.setStyle(tagid, "fill-rule:evenodd")
        }
    }

    renderAlarm(tagid: string, active: boolean) {
        if (active) {
            this.setStyle(tagid, "fill:#ff0000;fill-rule:evenodd;stroke:none")
        } else {
            this.setStyle(tagid, "fill:#00ff00;fill-rule:evenodd;stroke:none")
        }
    }

    play() {
        if (this.states.get("compressor_k1") === "run" || this.states.get("compressor") === "run" && !this.states.get("compressor_k1")) {
            this.do_bubble("stage_in_gas_k1")
            this.do_bubble("stage_12_gas_k1")
            this.do_bubble("stage_23_gas_k1")
            this.do_bubble("stage_34_gas_k1")
            this.do_bubble("stage_out_gas_k1")

            this.compressorWork("compressor_k1")
        }

        if (this.states.get("compressor_k2") === "run") {
            this.do_bubble("stage_in_gas_k2")
            this.do_bubble("stage_12_gas_k2")
            this.do_bubble("stage_23_gas_k2")
            this.do_bubble("stage_34_gas_k2")
            this.do_bubble("stage_out_gas_k2")

            this.compressorWork("compressor_k2")
        }

        if (this.states.get("bypass_valve_k1") === "open") {
            this.do_bubble("bypass_gas_out_k1")
            this.do_bubble("bypass_gas_in_k1")
        }

        if (this.states.get("bypass_valve_k2") === "open") {
            this.do_bubble("bypass_gas_out_k2")
            this.do_bubble("bypass_gas_in_k2")
        }

        if (this.states.get("inlet_valve_k1") === "open") {
            this.do_bubble("inlet_gas_k1")
        }

        if (this.states.get("inlet_valve_k2") === "open") {
            this.do_bubble("inlet_gas_k2")
        }

        if (this.states.get("phase") === "err") {
            this.setStyle("phaser", "x")
        } else {
            this.setStyle("phaser", "fill:#ff0000;stroke:none")
        }

        this.svgUse("timer_before_start", () => {
            return this.states.get("timer_before_start") == "run" ? "#timer-before-start-running" : "#timer-before-start-stopped"
        })

        this.svgUse("timer_before_start_k1", () => {
            return this.states.get("timer_before_start_k1") == "run" ? "#timer-before-start-running" : "#timer-before-start-stopped"
        })

        this.svgUse("timer_before_start_k2", () => {
            return this.states.get("timer_before_start_k2") == "run" ? "#timer-before-start-running" : "#timer-before-start-stopped"
        })

        if (this.states.get("venting_fan") === "run") this.venting("fan_state")

        this.renderSwitchingMode()

        this.renderValueBackground("inlet_pressure_k1_bg", "inlet_pressure_k1")
        this.renderValueBackground("inlet_pressure_k2_bg", "inlet_pressure_k2")
        this.renderValueBackground("blowdown_pressure_k1_bg", "blowdown_pressure_k1")
        this.renderValueBackground("blowdown_pressure_k2_bg", "blowdown_pressure_k2")
        this.renderValueBackground("stage_pressure_last_k1_bg", "stage_pressure_last_k1")
        this.renderValueBackground("stage_pressure_last_k2_bg", "stage_pressure_last_k2")
        this.renderValueBackground("stage_temperature_last_k1_bg", "stage_temperature_last_k1")
        this.renderValueBackground("stage_temperature_last_k2_bg", "stage_temperature_last_k2")
        this.renderValueBackground("storage_pressure_h_bg", "storage_pressure_h")
        this.renderValueBackground("storage_pressure_m_bg", "storage_pressure_m")
        this.renderValueBackground("storage_pressure_l_bg", "storage_pressure_l")
        this.renderValueBackground("pp_inlet_pressure_bg", "pp_inlet_pressure")

        // Pro jedno kompresorove stanice
        this.render_stage_pressure_high("stage-0", "stage_pressure_1")
        this.render_stage_pressure_high("stage-1", "stage_pressure_2")
        this.render_stage_pressure_high("stage-2", "stage_pressure_3")
        this.render_stage_pressure_high("stage-3", "stage_pressure_4")

        // Pro dvou kompresorove stanice
        this.render_stage_pressure_high("stage-0", "stage_pressure_1_k1")
        this.render_stage_pressure_high("stage-1", "stage_pressure_2_k1")
        this.render_stage_pressure_high("stage-2", "stage_pressure_3_k1")
        this.render_stage_pressure_high("stage-3", "stage_pressure_4_k1")
        this.render_stage_pressure_high("stagek2-0", "stage_pressure_1_k2")
        this.render_stage_pressure_high("stagek2-1", "stage_pressure_2_k2")
        this.render_stage_pressure_high("stagek2-2", "stage_pressure_3_k2")
        this.render_stage_pressure_high("stagek2-3", "stage_pressure_4_k2")

        this.animator++;

        this.setupButtonHook("mode", () => { this.dialogActive = "setMode" })
        this.setupButtonHook("alarm", () => { this.dialogActive = "resetAlarms" })
        this.setupButtonHook("switching_mode", () => { this.dialogActive = "switchingMode" })
        this.setupButtonHook("btn_sirena", () => { this.dialogActive = "switchSirena" })
    }

    renderSwitchingMode() {
        const tag = this.shadowRoot?.getElementById("switching_mode")
        if (!tag) return;

        const mode = this.states.get("switching_mode")
        if (!mode) return;

        const preferred = this.states.get("preferred")
        if (!preferred) return;

        let href = "";

        switch (mode) {
            case "both":
                href = "#btn_switching_mode_both_enabled";
                break;
            case "alt":
                href = "#btn_switching_mode_auto_enabled";
                break;
            case "auto":
                href = "#btn_switching_mode_auto_enabled";
                break;
            case "only":
                if (preferred === "compressor_k1") {
                    href = "#btn_switching_mode_k1_enabled";
                }
                if (preferred === "compressor_k2") {
                    href = "#btn_switching_mode_k2_enabled";
                }
                break;
        }

        tag.setAttribute("xlink:href", href)
    }

    renderValve(part: string, state: string) {
        const tag = this.shadowRoot?.getElementById(part)
        if (!tag) return;

        if (state === "open") {
            tag.setAttribute("xlink:href", "#unlocked")
        } else if (state === "close") {
            tag.setAttribute("xlink:href", "#locked")
        } else {
            tag.setAttribute("xlink:href", "")
        }
    }

    renderMode(part: string, state: string) {
        const tag = this.shadowRoot?.getElementById(part)
        if (!tag) return;

        switch (state) {
            case "off": tag.setAttribute("xlink:href", "#btn_off_enabled"); break;
            case "manual": tag.setAttribute("xlink:href", "#btn_manual_enabled"); break;
            case "auto": tag.setAttribute("xlink:href", "#btn_automat_enabled"); break;
            default: tag.setAttribute("xlink:href", "");
        }
    }

    renderPreventa(state: string) {
        const tag = this.shadowRoot?.getElementById("preventa_state")
        if (!tag) return;

        tag.setAttribute("xlink:href", `#preventa-${state}`);
    }

    renderAlarmButton(part: string, state: string) {
        const tag = this.shadowRoot?.getElementById(part)
        if (!tag) return;

        switch (state) {
            case "none": tag.setAttribute("xlink:href", "#btn_alarm_none"); break;
            case "soft": tag.setAttribute("xlink:href", "#btn_alarm_soft"); break;
            case "hard": tag.setAttribute("xlink:href", "#btn_alarm_hard"); break;
            default: tag.setAttribute("xlink:href", "");
        }
    }

    svgUse(tagid: string, use: () => string) {
        const tag = this.shadowRoot?.getElementById(tagid)
        if (!tag) return
        tag.setAttribute("xlink:href", use())
    }

    renderState(part: string, state: string) {
        if (state == "open" || state == "close") { this.renderValve(part, state); }
        if (part == "mode") { this.renderMode(part, state) }
        if (part == "alarm") { this.renderAlarmButton(part, state) }
        if (part == "preventa") { this.renderPreventa(state) }
        if (part == "ts") { this.renderHours("compressor_hours_val", state) }

        this.states = this.states.set(part, state)
    }

    renderAnalog(tagid: string, val: string) {
        const tag = this.shadowRoot?.getElementById(tagid)?.querySelector("tspan")
        if (!tag) return;

        try {
            let numval
            numval = parseFloat(`${val}`);
            numval = Math.floor(numval * 100) / 100;
            tag.innerHTML = `${numval}`;
        } catch (e) {
            tag.innerHTML = "???";
        }
    }

    renderHours(tagid: string, val: string) {
        const tag = this.shadowRoot?.getElementById(tagid)?.querySelector("tspan")
        if (!tag) return;
        tag.innerHTML = val;
    }

    closeDialog() {
        this.dialogActive = ""
    }

    static styles = css`
        section {
            width: 100%;
            height: 100%;
        }
        .scada-section {
            filter: blur(8px) opacity(25%);
            -webkit-filter: blur(8px) opacity(25%);
        }
        sp-dialog {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: lightblue;
        }
    `;

    setMode() {
        const setMode = (mode: string) => {
            return () => {
                controlStation({
                    sid: this.sid,
                    cmd: "setMode",
                    mode
                })
                this.closeDialog()
            }
        }
        return html`
                    <sp-theme theme="spectrum" scale="large" color="light">
                        <sp-dialog size="m" dismissable no-divider @close=${this.closeDialog}>
                            <h2 slot="heading">Zmenit rezim</h2>
                            <sp-button @click=${setMode("auto")}>Auto</sp-button>
                            <sp-button @click=${setMode("manual")}>Manual</sp-button>
                            <sp-button @click=${setMode("off")}>vypnuto</sp-button>
                        </sp-dialog>
                    </sp-theme>
                        `;
    }

    resetAlarms() {
        const resetAlarm = () => {
            controlStation({
                sid: this.sid,
                cmd: "resetAlarms",
            })
            this.closeDialog()
        }
        return html`
                    <sp-theme theme="spectrum" scale="large" color="light">
                        <sp-dialog size="m" dismissable no-divider @close=${this.closeDialog}>
                            <h2 slot="heading">Resetovat alarmy</h2>
                            <sp-button @click=${resetAlarm}>Ano</sp-button>
                            <sp-button @click=${this.closeDialog}>Ne</sp-button>
                        </sp-dialog>
                    </sp-theme>
        `
    }

    switchingMode() {
        const switching = (mode: string) => {
            return () => {
                controlStation({
                    sid: this.sid,
                    cmd: "switching",
                    mode
                })
                this.closeDialog()
            }
        }
        return html`
                    <sp-theme theme="spectrum" scale="large" color="light">
                        <sp-dialog size="l" dismissable no-divider @close=${this.closeDialog}>
                            <h2 slot="heading">Stridani kompresoru</h2>
                            <sp-button @click=${switching("both")}>K1+K2</sp-button>
                            <sp-button @click=${switching("auto")}>Auto</sp-button>
                            <sp-button @click=${switching("k1")}>K1</sp-button>
                            <sp-button @click=${switching("k2")}>K2</sp-button>
                            <sp-button @click=${this.closeDialog}>Ne</sp-button>
                        </sp-dialog>
                    </sp-theme>
        `
    }

    switchSirena() {
        const sirena = (mode: string) => {
            return () => {
                controlStation({
                    sid: this.sid,
                    cmd: "sirena",
                    mode
                })
                this.closeDialog()
            }
        }
        return html`
                    <sp-theme theme="spectrum" scale="large" color="light">
                        <sp-dialog size="l" dismissable no-divider @close=${this.closeDialog}>
                            <h2 slot="heading">Sirenu</h2>
                            <sp-button @click=${sirena("enable")}>Aktivovat</sp-button>
                            <sp-button @click=${sirena("disable")}>Deaktivovat</sp-button>
                        </sp-dialog>
                    </sp-theme>
        `
    }

    render() {
        let svg = this.svg
        svg = svg.replace(/width="[0-9.]*"/, 'width="99%"')
        svg = svg.replace(/height="[0-9.]*"/, 'height="99%"')

        let modalDialog
        switch (this.dialogActive) {
            case "setMode":
                modalDialog = this.setMode()
                break
            case "resetAlarms":
                modalDialog = this.resetAlarms()
                break
            case "switchingMode":
                modalDialog = this.switchingMode()
                break
            case "switchSirena":
                modalDialog = this.switchSirena()
                break
            default:
                modalDialog = html``
        }

        return html`
            <section class="${this.dialogActive ? 'scada-section' : ''}">
                ${unsafeHTML(svg)}
            </section>
            ${modalDialog}
        `;
    }
}