import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { Subscription } from 'rxjs';
import { DBUser, login$, Station, stationChange } from './firebase';

import '@spectrum-web-components/theme/src/themes.js';
import '@spectrum-web-components/theme/sp-theme.js';
import '@spectrum-web-components/button/sp-button.js';
import '@spectrum-web-components/card/sp-card.js';
import '@spectrum-web-components/action-menu/sp-action-menu.js';
import '@spectrum-web-components/menu/sp-menu-item.js';

import { mqttChanges } from './mqttsvc';
import { Map } from 'immutable';

@customElement('cng-stations')
export class CngStations extends LitElement {
    @state() stations: Station[] = []
    @state() user: DBUser | null = null
    @state() states: Map<string, string> = Map()
    @state() analogs: Map<string, number> = Map()

    susbcriptions: Subscription[] = []

    override connectedCallback() {
        super.connectedCallback()
        this.susbcriptions.push(
            login$.subscribe(user => {
                this.user = user
            })
        )

        this.susbcriptions.push(
            stationChange().subscribe(stations => {
                this.stations = stations
            })
        )

        this.susbcriptions.push(
            mqttChanges().subscribe(v => {
                if (v.kind === "analog") {
                    const key = `${v.sid}-${v.vid}`
                    this.analogs = this.analogs.set(key, v.value)
                }

                if (v.kind === "state") {
                    const key = `${v.sid}-${v.part}`
                    this.states = this.states.set(key, v.state)
                }
            }))
    }

    disconnectedCallback(): void {
        super.disconnectedCallback()
        this.susbcriptions.forEach(s => { s.unsubscribe() })
    }

    renderStation(s: Station) {
        const mode = this.states.get(`${s.sid}-mode`)
        const alarm = this.states.get(`${s.sid}-alarm`)
        const msg = alarm === "none" ? mode : alarm
        const compressor = this.states.get(`${s.sid}-compressor`)
        const storage = this.analogs.get(`${s.sid}-storage_pressure_h`)

        const select = () => {
            const event = new CustomEvent('selected-station', { bubbles: true, composed: true, detail: s });
            this.dispatchEvent(event);
        }

        const grafana = () => {
            window.open(s.grafana);
        }

        const trello = () => {
            window.open(s.trello);
        }

        return html`
        <sp-card heading="${s.name}" subheading="${msg}" class="alarm-${alarm}">
            <img slot="cover-photo" src=${s.imgurl} alt=${s.name} />
            <div slot="description">${compressor} (${storage} bar)</div>
            <sp-action-menu slot="actions" placement="bottom-end">
                <sp-menu-item @click=${select}>Scada</sp-menu-item>
                <sp-menu-item @click=${grafana}>Grafana</sp-menu-item>
                <sp-menu-item @click=${trello}>Trello</sp-menu-item>
            </sp-action-menu>
        </sp-card>
        `
    }

    static styles = css`
        sp-card {
            margin: 6px;
        }
        .alarm-none {
            background-color: white;
        }
        .alarm-soft {
            background-color: orange;
        }
        .alarm-notice {
            background-color: yellow;
        }
        .alarm-hard {
            background-color: red;
        }
        p {
            text-align: center;
            width: 100%;
        }
        .stations {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
    `;

    render() {
        let menu = html``
        if (this.user && this.user.alarms) {
            menu = html`<p><a href="${this.user.alarms}" target="_blank">Alarmy</a></p>`
        }

        return html`
            <sp-theme theme="spectrum" scale="large" color="light">
                ${menu}
                <div class="stations">
                    ${this.stations.map(s => this.renderStation(s))}
                </div>
            </sp-theme>
        `;
    }
}