import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { Station } from './firebase';
import "./scada"
import "./stations"
import "./login"

@customElement('cng-man-app')
export class App extends LitElement {
    @state() selected: Station | null = null

    override connectedCallback() {
        super.connectedCallback()

        window.onpopstate = (event) => {
            console.log(event)
            this.selected = null
        }
    }

    selectStation(e: CustomEvent) {
        const station = e.detail
        console.log("VYBRANA", station)
        this.selected = station
        history.pushState(station, station.name, "?sid=" + station.sid)
    }

    renderScada(station: Station) {
        const svg = station.template.replace(".html", "") + ".svg"
        console.log(svg)

        return html`
            <station-scada sid=${station.sid} template=${svg}></station-scada>
        `
    }

    renderStations() {
        return html`
            <cng-man-login></cng-man-login>
            <cng-stations @selected-station=${this.selectStation}></cng-stations>
        `
    }

    render() {
        const content = this.selected ? this.renderScada(this.selected) : this.renderStations()

        return html`${content}`
        //return html`<station-scada sid="zdiby" template="hruby.svg"></station-scada>`
        //return html`<station-scada sid="benesov" template="benesov.svg"></station-scada>`
    }
}

async function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.register('sw.js', { scope: '/', })
            if (registration.installing) {
                console.log('Service worker installing');
            } else if (registration.waiting) {
                console.log('Service worker installed');
            } else if (registration.active) {
                console.log('Service worker active');
            }
        } catch (error) {
            console.error(`Registration failed with ${error}`);
        }
    }
}

registerServiceWorker()