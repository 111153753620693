import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { getFirestore, collection, query, onSnapshot, doc, getDoc, where, addDoc, setDoc } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { from, map, merge, Observable, shareReplay, Subject, switchMap } from "rxjs";

export type Station = {
    sid: string,
    name: string,
    doc: string,
    imgurl: string,
    template: string,
    storage: number,
    mode: string,
    compressor: string,
    alarm: string,
    grafana: string,
    trello: string
}

export type ResetAlarmCommand = {
    cmd: "resetAlarms"
    sid: string
}

export type SetModeCommand = {
    cmd: "setMode"
    sid: string
    mode: string
}

export type SetSwitchingCommand = {
    cmd: "switching"
    sid: string
    mode: string
}

export type SirenaCommand = {
    cmd: "sirena"
    sid: string
    mode: string
}

export type ControlCommand = ResetAlarmCommand | SetModeCommand | SetSwitchingCommand | SirenaCommand

const firebaseConfig = {
    apiKey: "AIzaSyB10CAtmKLix9zzWf-HTSd_HY1RSyqiJFs",
    authDomain: "puma-64bac.firebaseapp.com",
    databaseURL: "https://puma-64bac.firebaseio.com",
    projectId: "puma-64bac",
    storageBucket: "puma-64bac.appspot.com",
    messagingSenderId: "342719571576",
    appId: "1:342719571576:web:62c3d549188f1d1a2c2f8d",
    measurementId: "G-GZ6MNN7C5Z"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const auth = getAuth();
// var ui = new firebaseui.auth.AuthUI(auth);
const db = getFirestore(firebaseApp)

export const user$ = new Subject<User>().pipe(shareReplay())

export const login$ = user$.pipe(switchMap(user => from(getGroup(user))), shareReplay())

onAuthStateChanged(getAuth(), async user => {
    console.log("Uzivatel je prihlasen", user)
    user$.next(user)

    const token = await getMessagingToken()
    console.log("token: ", token)

    const ref = doc(collection(db, "login"), user?.uid)
    await setDoc(ref, { messaging_token: token }, { merge: true })
})

export type DBUser = {
    alarms?: string,
    displayName: string,
    email: string,
    group: string
}

async function getGroup(user: User) {
    if (!user) return null
    const docRef = doc(db, "users", user.uid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        const dbuser = docSnap.data() as DBUser
        return dbuser
    } else {
        return null
    }
}

export function stationChange(): Observable<Station> {
    return user$.pipe(
        switchMap(user => from(getGroup(user))),
        map(user => user?.group),
        switchMap(group => {
            console.log("group", group)

            if (!group) {
                return from([])
            } else {
                return new Observable<Station>(subscriber => {
                    const q = query(collection(db, "stations"), where("manage", "array-contains", group));

                    const unsubscribe = onSnapshot(q, qs => {
                        const stations: Station[] = []
                        qs.forEach((doc) => {
                            stations.push(doc.data() as Station)
                        })
                        subscriber.next(stations)
                    })

                    return () => {
                        unsubscribe()
                    }
                })
            }
        }))
}

export async function controlStation(cmd: ControlCommand) {
    console.log("control station: ", cmd)
    const user = getAuth().currentUser
    if (!user) {
        console.log(cmd, "Ignoruji - uzivatel neni prihalsen");
        return;
    }

    const command = {
        ...cmd,
        create_ts: Date.now().toString(),
        email: user.email || "not known",
        state: "request",
        uid: user.uid,
        version: "2"
    }

    console.log("pridavam: ", command)

    const docRef = await addDoc(collection(db, "control"), command);

    console.log("Pridan control: ", command, docRef.id);
}

// messaging in foreground

async function getMessagingToken() {
    return await getToken(getMessaging(), { vapidKey: "BK0t84OpaKu2Qy8__kGu1sVRhUMBTVYwYU0Nju4Gu_viTd2q8zaPZbYCs_5VlVQUkLTKjus1Kxe9Od5xCPU6V_g" })
}

onMessage(getMessaging(), (payload) => {
    console.log('Message received na popredi. ', payload);
});

function requestNotificationPermission() {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
        }
    })
}

requestNotificationPermission()


